/** @jsx jsx */
//import * as React from "react"
import { jsx, css } from "@emotion/react"
import { SEO } from "../components/seo"

import { Link } from "gatsby"
import Page from "../layouts/page"

import { headerNavItems } from "../components/header"

const styles = css`

`

// markup
const MenuPage = () => {
  return <Page>
    <div css={styles}>
      <div className="contain page-body">
        <h1>Menu</h1>

        <ul>
          {headerNavItems.map((item) => 
          <li key={item.name}>
            <Link to={item.url}>{item.name}</Link>
          </li>
          )}
        </ul>
        
      </div>
    </div>
  </Page>
}

export const Head = () => (
  <SEO
    title="Menu"
  />
)

export default MenuPage
